<template>
  <el-container style="height: 100%">
    <el-header style="padding: 0; height: 40px">
      <div id="navigation">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/myFolder' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ currFolder.name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-header>

    <el-container style="height: calc(100% - 40px); background: #f4f4f4">
      <el-container style="box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2); border-radius: 8px; background: white">
        <div style="padding: 20px 20px 0 20px">
          <div style="float: left">
            <el-form :inline="true" ref="dataForm" :model="dataForm" @keyup.enter.native="getDataList(true)">
              <el-form-item prop="name">
                <el-input v-model="dataForm.name" placeholder="文件名称" clearable></el-input>
              </el-form-item>
              <el-form-item prop="dateTime">
                <el-date-picker
                  v-model="dataForm.dateTime"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  range-separator="→"
                  start-placeholder="选择开始日期"
                  end-placeholder="选择结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  align="right"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button @click="getDataList(true)" type="primary">查询</el-button>
                <el-button @click="refreshForm()" style="background: rgb(30, 30, 242, 0.1); color: #5053dd">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div style="float: right">
            <el-form :inline="true">
              <el-form-item>
                <el-button @click="toAnalysisMore()" type="primary" :disabled="analysisMoreDisable">多图分析</el-button>
                <el-button @click="toDown()" type="primary" :disabled="btnDisabled">下载</el-button>
                <el-button @click="toDelete()" type="danger" :disabled="btnDisabled">删除</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <el-main style="padding: 0 20px 20px 20px">
          <div style="height: 1px; background: #d4d6d9; opacity: 0.5"></div>
          <div class="main">
            <div v-if="dataList.length" v-infinite-scroll="getDataList" :infinite-scroll-distance="1">
              <div
                v-for="(item, index) in dataList"
                :key="index"
                :style="fileStyle(index)"
                class="inner-img"
                @click="imgClick({ item, index, isClick: 1 })"
                @dblclick="imgDbClick(item, index, 2)"
                @mouseenter="imgMouseenter(item)"
                @mouseleave="imgMouseleave(item)"
              >
                <div class="img-item">
                  <el-image v-if="/\.([0-9A-z]+)$/.exec(item.name)[0] === '.jpg'" :src="resizeImgUrl(item.url)" fit="contain">
                    <div slot="error" style="display: flex; height: 100%">
                      <el-image :src="require(`@/assets/img/loading-failed.png`)" fit="contain"> </el-image>
                    </div>
                  </el-image>
                  <el-image v-else-if="item.viewUrl" :src="resizeImgUrl(item.viewUrl)" fit="contain">
                    <div slot="error" style="display: flex; height: 100%">
                      <el-image :src="require(`@/assets/img/loading-failed.png`)" fit="contain"> </el-image>
                    </div>
                  </el-image>
                  <el-image v-else :src="require(`@/assets/img/other-formats.png`)" fit="contain"> </el-image>
                </div>

                <div class="img-title">
                  <el-tooltip effect="dark" :content="item.name" placement="bottom-start" :visible-arrow="false">
                    <span> {{ item.name }} </span>
                  </el-tooltip>
                </div>
                <div class="img-check" v-show="item.isShow">
                  <el-checkbox v-model="checkedList[index]"></el-checkbox>
                </div>
                <el-popover trigger="click" :ref="`popover-${index}`" placement="right">
                  <div @click="toDown(item)" class="popover-btn">下载</div>
                  <div @click="toDelete(item)" class="popover-btn">删除</div>
                  <div class="more" v-show="item.isShow" v-bind:title="'更多操作'" slot="reference">
                    <i class="el-icon-more"></i>
                  </div>
                </el-popover>
                <div
                  v-bind:title="'预览'"
                  class="img-view"
                  @click="viewImg(item)"
                  v-if="/\.([0-9A-z]+)$/.exec(item.name)[0] === '.jpg' || item.viewUrl"
                  v-show="item.isShow"
                >
                  <span class="iconfont" style="font-size: 18px">&#xe688;</span>
                </div>
              </div>
            </div>

            <div v-else style="height: 100%; text-align: center">
              <el-image :src="require('@/assets/img/no-data.png')" fit="contain" style="margin: 10% 0 24px 0"> </el-image>
              <div style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #73767a">啊哦~这里没有数据呢~</div>
            </div>
          </div>
        </el-main>
        <el-footer style="height: 0"> </el-footer>
      </el-container>
      <el-aside style="height: 100%; width: 20px; background: #f4f4f4"></el-aside>
      <el-aside width="323px" class="info-aside">
        <div style="font-size: 16px; font-family: PingFangSC-Medium, PingFang SC; font-weight: bold; color: #3a3b3e; margin: 24px 24px 24px 15px">
          详细信息
        </div>

        <div v-if="showFlag">
          <div v-if="showEnvironment">
            <el-divider content-position="left">单位切换</el-divider>
            <el-form ref="imgInfo" label-width="90px" label-position="left" class="form-class">
              <el-form-item label="温度单位">
                <el-select v-model="imgInfo.temUnit" placeholder="温度单位">
                  <el-option v-for="item in temUnits" :label="item.name" :value="item.code" :key="item.code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="距离单位">
                <el-select v-model="imgInfo.disUnit" placeholder="距离单位">
                  <el-option v-for="item in disUnits" :label="item.name" :value="item.code" :key="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-divider content-position="left">环境信息</el-divider>
            <el-form ref="imgInfo" label-width="180px" label-position="left" class="form-class">
              <el-form-item label="大气透过率(0.1~1)">
                <el-input-number v-model="imgInfo.envObj.fHumidity" :precision="2" :controls="false" :disabled="true"></el-input-number>
              </el-form-item>
              <el-form-item label="发射率(0.1~1)">
                <el-input-number v-model="imgInfo.envObj.fEmiss" :precision="2" :controls="false" :disabled="true"></el-input-number>
              </el-form-item>
              <el-form-item :label="`距离(${distRange[0] + '~' + distRange[1]})`">
                <el-input-number v-model="distance" :precision="2" :controls="false" :disabled="true"></el-input-number>
              </el-form-item>
              <el-form-item :label="`环境温度(${tempRange[0] + '~' + tempRange[1]})`">
                <el-input-number v-model="airTemp" :precision="2" :controls="false" :disabled="true"></el-input-number>
              </el-form-item>
              <el-form-item :label="`反射温度(${tempRange[0] + '~' + tempRange[1]})`">
                <el-input-number v-model="reflectTemp" :precision="2" :controls="false" :disabled="true"></el-input-number>
              </el-form-item>
            </el-form>
            <el-divider content-position="left">文件信息</el-divider>
          </div>

          <el-descriptions
            :column="1"
            :colon="false"
            labelClassName="detail-label"
            :labelStyle="{ 'margin-left': '15px !important' }"
            style="word-break: break-all"
          >
            <el-descriptions-item label="产品系列" v-if="showEnvironment">{{ imgInfo.deviceObj.suit }}</el-descriptions-item>
            <el-descriptions-item label="设备型号" v-if="showEnvironment">{{ imgInfo.deviceObj.model }}</el-descriptions-item>
            <el-descriptions-item label="设备名称" v-if="showEnvironment">{{ imgInfo.deviceObj.name }}</el-descriptions-item>
            <el-descriptions-item label="文件名称">{{ imgInfo.imgObj.name }}</el-descriptions-item>
            <el-descriptions-item label="图像分辨率" v-if="showEnvironment">{{ imgInfo.imgObj.dpi }}</el-descriptions-item>
            <el-descriptions-item label="创建日期">{{ imgInfo.imgObj.uploadTime }}</el-descriptions-item>
            <el-descriptions-item label="大小">{{ imgInfo.imgObj.fileSize }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div v-else style="height: calc(100% - 256px); padding: 0 15px">
          <img :src="require('@/assets/img/img_sample_detailed-info.png')" width="96%" alt="" />
          <div style="font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #4d4e52; text-align: center; margin-top: 20px">
            选择以展示详细信息
          </div>
        </div>
      </el-aside>
      <el-image-viewer v-if="showViewer" :url-list="srcList" :on-close="closeViewer"></el-image-viewer>
    </el-container>
  </el-container>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { getParams } from '@/utils/temperature.js'
import { findStr, changeToThousand } from '@/utils'
import axios from 'axios'
// import Vue from 'vue'
import { changeFileUrl } from '@/utils/changeUrl.js'
export default {
  name: 'MyImg',
  components: {
    ElImageViewer
  },
  data() {
    return {
      dataForm: {
        name: '',
        dateTime: []
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      temUnits: [
        { code: 1, name: '摄氏度' },
        { code: 2, name: '华氏度' },
        { code: 3, name: '开尔文' }
      ],
      disUnits: [
        { code: 1, name: '米' },
        { code: 2, name: '英尺' }
      ],
      imgInfo: {
        temUnit: 1,
        disUnit: 1,
        envObj: {
          fHumidity: undefined,
          fEmiss: undefined,
          fDistance: undefined,
          fAirTemp: undefined,
          fReflectTemp: undefined
        },
        deviceObj: {
          suit: '',
          model: '',
          name: ''
        },
        imgObj: {
          name: '',
          dpi: '',
          uploadTime: '',
          fileSize: ''
        }
      },
      dataList: [],
      checkedList: [],
      currentIndex: undefined,
      currentFile: {},
      dataListSelectIds: [],
      dataListSelects: [],
      timeOutId: undefined,
      pageIndex: 1,
      pageSize: 54,
      totalPage: 1,
      asideLoading: false,
      irList: [],
      dcList: [],
      irgList: [],
      currFolder: {},
      isClick: 1,
      irUrl: '',
      dcUrl: '',
      irgUrl: '',
      isIrg: false,
      showViewer: false,
      srcList: [],
      analysisMoreDisable: true,
      validList: [], // 多图分析有效的文件
      showFlag: false,
      showEnvironment: true,
      resizeW: 50,
      resizeH: 50
    }
  },
  computed: {
    btnDisabled() {
      return this.dataListSelectIds.length === 0
    },
    fileStyle() {
      return (index) => {
        return this.currentIndex === index ? 'box-shadow: 0px 4px 10px 0px rgba(168, 171, 179, 0.29);border-radius: 8px;' : ''
      }
    },
    // 距离范围
    distRange() {
      if (this.imgInfo.disUnit === 2) {
        // 英尺
        return [0.25 * 3.28, 20 * 3.28]
      } else {
        // 米
        return [0.25, 20]
      }
    },
    // 距离
    distance: {
      get() {
        if (this.imgInfo.disUnit === 2) {
          // 英尺
          return this.imgInfo.envObj.fDistance * 3.28
        } else if (this.imgInfo.disUnit === 1) {
          // 米
          return this.imgInfo.envObj.fDistance
        } else {
          return ''
        }
      },
      set() {}
    },
    // 温度范围
    tempRange() {
      if (this.imgInfo.temUnit === 2) {
        // 华氏度
        return [-10 * 1.8 + 32, 50 * 1.8 + 32]
      } else if (this.imgInfo.temUnit === 3) {
        // 开尔文
        return [263.15, 323.15]
      } else {
        // 摄氏度
        return [-10, 50]
      }
    },
    // 环境温度
    airTemp: {
      get() {
        if (this.imgInfo.envObj.fAirTemp) {
          if (this.imgInfo.temUnit === 3) {
            // 开尔文
            return this.imgInfo.envObj.fAirTemp
          } else if (this.imgInfo.temUnit === 2) {
            // 华氏度
            return (this.imgInfo.envObj.fAirTemp - 273.15) * 1.8 + 32
          } else if (this.imgInfo.temUnit === 1) {
            // 摄氏度
            return this.imgInfo.envObj.fAirTemp - 273.15
          } else {
            return undefined
          }
        } else {
          return undefined
        }
      },
      set() {}
    },
    // 环境温度
    reflectTemp: {
      get() {
        if (this.imgInfo.envObj.fReflectTemp) {
          if (this.imgInfo.temUnit === 3) {
            // 开尔文
            return this.imgInfo.envObj.fReflectTemp
          } else if (this.imgInfo.temUnit === 2) {
            // 华氏度
            return (this.imgInfo.envObj.fReflectTemp - 273.15) * 1.8 + 32
          } else if (this.imgInfo.temUnit === 1) {
            // 摄氏度
            return this.imgInfo.envObj.fReflectTemp - 273.15
          } else {
            return undefined
          }
        } else {
          return undefined
        }
      },
      set() {}
    }
  },
  watch: {
    checkedList(val) {
      this.dataListSelects = []
      this.dataListSelectIds = []
      for (let i = 0; i < val.length; i++) {
        if (val[i]) {
          this.dataListSelects.push(this.dataList[i])
          this.dataListSelectIds.push(this.dataList[i].id)
        }
      }
    },
    dataListSelects(val) {
      if (val.length >= 2) {
        // 判断是否包含jpg irg 统计文件数
        let containNum = 0
        this.validList = []
        for (let i = 0; i < val.length; i++) {
          if (val[i].name.indexOf('.jpg') !== -1 || val[i].name.indexOf('.irg') !== -1) {
            this.validList.push(val[i])
            containNum++
          }
        }
        // 满足2个，可点击多图分析
        if (containNum >= 2) {
          this.analysisMoreDisable = false
        }
      } else {
        this.analysisMoreDisable = true
      }
    }
  },
  created() {
    // 当前文件夹
    this.currFolder = JSON.parse(sessionStorage.currFolder || '{}')
    // 跳转下级前的分页信息
    const pageInfo = JSON.parse(sessionStorage.filePage || '{}')
    if (Object.keys(pageInfo).length > 0) {
      this.pageIndex = pageInfo.pageIndex
      this.pageNum = pageInfo.pageNum
    }
    this.getDataList(true)
  },
  methods: {
    // 设置图片长宽
    resizeImgUrl(url) {
      return url + (url.includes('aliyun') ? '' : `_resize_?w=${this.resizeW}&h=${this.resizeH}`)
    },
    // 鼠标移入
    imgMouseenter(item) {
      this.$set(item, 'isShow', true)
    },
    // 鼠标移出
    imgMouseleave(item) {
      if (this.dataListSelectIds.indexOf(item.id) === -1) {
        this.$set(item, 'isShow', false)
      }
    },
    // 关闭预览
    closeViewer() {
      this.showViewer = false
    },
    // 图片预览
    viewImg(item) {
      this.srcList = [item.viewUrl ? item.viewUrl : item.url]
      this.showViewer = true
    },
    // 多图分析
    toAnalysisMore() {
      // 判断所选是否是相同文件
      let sameFlag = false
      if (this.validList.length === 2) {
        const firstName = this.validList[0].name
        const secondName = this.validList[1].name
        if (
          firstName &&
          secondName &&
          (firstName.split('.')[0] === secondName.split('.')[0] ||
            firstName.replace('-DC', '-IR') === secondName ||
            firstName.replace('-IR', '-DC') === secondName)
        ) {
          sameFlag = true
        }
      }

      if (sameFlag) {
        // 进单独分析
        this.imgDbClick(this.validList[0], undefined, 2)
      } else {
        // 进多图分析
        const requestList = []
        const analysisObjList = []
        const fileNames = []
        let stopFlag = false

        for (let i = 0; i < this.validList.length; i++) {
          const item = this.validList[i]
          let name = item.name

          // 判空
          if (!name) {
            continue
          }
          const fileName = name.split('.')[0]

          // 判断是否重复
          if (
            fileNames.indexOf(fileName) !== -1 ||
            fileNames.indexOf(fileName.replace('-IR', '-DC')) !== -1 ||
            fileNames.indexOf(fileName.replace('-DC', '-IR')) !== -1
          ) {
            continue
          }

          // 不重复
          fileNames.push(fileName)
          requestList.push(() => {
            let irgUrl = ''
            let irUrl = ''
            let dcUrl = ''
            let isIR = false
            let isDC = false
            if (name.indexOf('-IR') !== -1) {
              isIR = true
              name = name.replace('-IR', '-DC')
              irUrl = item.url
            } else if (name.indexOf('-DC') !== -1) {
              isDC = true
              name = name.replace('-DC', '-IR')
              dcUrl = item.url
            } else {
              name = name.replace('jpg', 'irg')
            }

            return this.$http({
              url: this.$http.adornUrl('/api/v1/files/downloadFile'),
              method: 'post',
              params: this.$http.adornParams({
                deviceId: this.currFolder.deviceId,
                name,
                size: 0
              })
            })
              .then((res) => {
                const url = res.data
                if (res.code === 0 && url) {
                  if (isIR) {
                    dcUrl = url
                  } else if (isDC) {
                    irUrl = url
                  } else {
                    irgUrl = url
                  }
                  analysisObjList.push({ isIrg: !(isIR || isDC), irgUrl, irUrl, dcUrl, fileName, deviceName: this.currFolder.name })
                } else {
                  this.$message.error(`匹配不到${name}的测温分析文件，不能进入多图分析`)
                  stopFlag = true
                }
              })
              .catch(() => {
                this.$message.error(`匹配不到${name}的测温分析文件，不能进入多图分析`)
                stopFlag = true
              })
          })
        }

        let j = 0
        const send = async () => {
          // 报错了
          if (stopFlag) {
            return
          }

          // 完成
          if (j >= requestList.length) {
            if (analysisObjList.length > 10) {
              this.$message.error('多图分析最多分析10组')
              return
            }
            // 所选文件信息
            sessionStorage.analysisMore = JSON.stringify(analysisObjList)
            // 当前分页信息
            sessionStorage.filePage = JSON.stringify({ pageIndex: this.pageIndex, pageNum: this.pageNum })
            this.$router.push('/analysisMore')
          } else {
            await requestList[j]()
            j++
            send()
          }
        }

        send()
      }
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1)
    },
    // 下载
    toDown(item) {
      const ids = item ? [item.id] : this.dataListSelectIds
      this.$http({
        url: this.$http.adornUrl('/api/v1/files/twinning'),
        method: 'post',
        data: this.$http.adornParams(ids, false, 'text')
      }).then((res) => {
        if (res.code === 0) {
          // 判断是否选全
          const allIds = res.data || []

          window.open(
            this.$http.adornUrl('/api/v1/files/download') + '?imageIds=' + allIds.toString() + '&fileName=' + this.currFolder.name,
            '_blank'
          )
          // window.location.href =
          //   this.$http.adornUrl('/api/v1/files/download') + '?imageIds=' + allIds.toString() + '&fileName=' + this.currFolder.name

          // axios({
          //   url: this.$http.adornUrl('/api/v1/files/download'),
          //   method: 'post',
          //   params: {
          //     imageIds: allIds.toString()
          //   },
          //   responseType: 'blob',
          //   headers: {
          //     Authorization: Vue.cookie.get('token')
          //   }
          // }).then(res => {
          //   const blob = res.data
          //   const fileName = this.currFolder.name + '.zip'

          //   if ('download' in document.createElement('a')) {
          //     // 非IE下载
          //     const elink = document.createElement('a')
          //     elink.download = fileName
          //     elink.style.display = 'none'
          //     elink.href = URL.createObjectURL(blob)
          //     document.body.appendChild(elink)
          //     elink.click()
          //     URL.revokeObjectURL(elink.href) // 释放URL 对象
          //     document.body.removeChild(elink)
          //   } else {
          //     // IE10+下载
          //     navigator.msSaveBlob(blob, fileName)
          //   }
          // })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 删除
    toDelete(item) {
      const ids = item ? [item.id] : this.dataListSelectIds
      this.$http({
        url: this.$http.adornUrl('/api/v1/files/twinning'),
        method: 'post',
        data: this.$http.adornParams(ids, false, 'text')
      }).then((res) => {
        if (res.code === 0) {
          // 判断是否选全
          const allIds = res.data || []
          const notSelects = allIds.filter((id) => {
            return this.dataListSelectIds.indexOf(id.toString()) === -1
          })

          this.$confirm(
            `<div >
              <div class="tmc_confirm_icon"></div>
              <div class="tmc_confirm_title">
                ${notSelects.length ? '所选热像图的同组图像也会一并删除，确定删除所选文件吗?' : '确定删除所选文件吗? '}
              </div> 
              <div class="tmc_confirm_content">站内文件也占用容量空间，删除的文件可在10天内通过回收站还原</div>
              </div>`,
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              dangerouslyUseHTMLString: true,
              center: true,
              customClass: 'tmc_confirm'
            }
          )

            .then(() => {
              this.$http({
                url: this.$http.adornUrl('/api/v1/files/remove'),
                method: 'post',
                params: this.$http.adornParams({
                  ids: allIds.toString()
                })
              }).then((res) => {
                if (res.code === 0) {
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500,
                    onClose: () => {
                      this.getDataList(true)
                    }
                  })
                } else {
                  this.$message.error(res.msg)
                }
              })
            })
            .catch(() => {})
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    imgDbClick(item, index, isClick) {
      // 触发参数解析
      new Promise((resolve, reject) => {
        clearTimeout(this.timeOutId)
        this.isClick = isClick
        this.imgClick({ item, index, resolve, reject })
      })
        .then(() => {
          // 当前文件信息
          if (this.imgInfo.envObj.gwType === 'PX1' && (!this.imgInfo.envObj.dcU8Array || this.imgInfo.envObj.dcU8Array.length === 0)) {
            sessionStorage.analysis = JSON.stringify({
              deviceObj: this.imgInfo.deviceObj,
              imgObj: this.imgInfo.imgObj,
              isPX1: true,
              params: this.imgInfo.envObj
            })
          } else {
            sessionStorage.analysis = JSON.stringify({
              isIrg: this.isIrg,
              irgUrl: this.irgUrl,
              irUrl: this.irUrl,
              dcUrl: this.dcUrl,
              deviceObj: this.imgInfo.deviceObj,
              imgObj: this.imgInfo.imgObj
            })
          }

          // 当前分页信息
          sessionStorage.filePage = JSON.stringify({ pageIndex: this.pageIndex, pageNum: this.pageNum })
          this.$router.push('/analysis')
        })
        .catch((err) => {
          console.log('文件解析失败', err)
        })
    },
    // 获取图像
    getImgData(url, type, resolve, reject) {
      axios
        .get((url.indexOf('aliyuncs') !== -1 ? '/alImg' : '/dfsImg') + url.substr(findStr(url, '/', 2)), {
          responseType: 'blob'
        })
        .then((res) => {
          const blob = new Blob([res.data])
          const reader = new FileReader()
          reader.readAsArrayBuffer(blob)
          reader.onload = (e) => {
            const list = new Uint8Array(e.target.result)
            if (type === 'ir') {
              this.irList = list
              this.irUrl = url
              this.isIrg = false
              resolve()
            } else if (type === 'dc') {
              this.dcList = list
              this.dcUrl = url
              this.isIrg = false
              resolve()
            } else if (type === 'irg') {
              this.irgList = list
              this.irgUrl = url
              this.isIrg = true

              // 环境信息
              let params = {}
              try {
                params = getParams({ isGW: false, irgU8Array: list })
              } catch (error) {
                if (this.isClick === 2) {
                  this.$message.error('同组测温文件来源不一致，不能进入测温分析')
                }
                if (reject) {
                  reject()
                }
              }
              this.imgInfo.envObj = params

              // 设备信息
              const { suit, model, name } = this.currFolder
              this.imgInfo.deviceObj = { suit, model, name }

              // 图像信息
              this.imgInfo.imgObj = {
                name: this.currentFile.name,
                uploadTime: this.currentFile.uploadTime,
                fileSize: changeToThousand(this.currentFile.size.toString()) + 'KB',
                dpi: params.iwidth && params.iheight ? params.iwidth + '*' + params.iheight : ''
              }

              if (resolve) {
                resolve()
              }
            }
          }
        })
        .catch((err) => {
          console.log('err => ', err)
        })
    },
    // 通过名称获取地址
    getUrlByName(name, resolve, irUrl) {
      return this.$http({
        url: this.$http.adornUrl('/api/v1/files/downloadFile'),
        method: 'post',
        params: this.$http.adornParams({
          deviceId: this.currFolder.deviceId,
          name,
          size: 0
        })
      }).then((res) => {
        if (res.code === 0) {
          const data = res.data
          resolve(data)
        } else {
          // 设备信息
          const { suit, model, name } = this.currFolder
          this.imgInfo.deviceObj = { suit, model, name }

          // 图像信息
          this.imgInfo.imgObj = {
            name: this.currentFile.name,
            uploadTime: this.currentFile.uploadTime,
            fileSize: changeToThousand(this.currentFile.size.toString()) + 'KB',
            dpi: ''
          }

          // 兼容PX1
          if (this.currentFile.name.indexOf('-IR') !== -1) {
            // 可能是PX1单IR格式
            axios
              .get((irUrl.indexOf('aliyuncs') !== -1 ? '/alImg' : '/dfsImg') + irUrl.substr(findStr(irUrl, '/', 2)), {
                responseType: 'blob'
              })
              .then((res) => {
                const blob = new Blob([res.data])
                const reader = new FileReader()
                reader.readAsArrayBuffer(blob)
                reader.onload = (e) => {
                  const list = new Uint8Array(e.target.result)
                  // 解析参数
                  let params = {}
                  try {
                    params = getParams({ isGW: true, irU8Array: list })
                    if (params.gwType === 'PX1') {
                      this.imgInfo.envObj = params
                      this.imgInfo.imgObj.dpi = params.irWidth && params.irHeight ? params.irWidth + '*' + params.irHeight : ''
                      if (this.isClick === 2) {
                        // 当前文件信息
                        sessionStorage.analysis = JSON.stringify({
                          deviceObj: this.imgInfo.deviceObj,
                          imgObj: this.imgInfo.imgObj,
                          isPX1: true,
                          params
                        })
                        // 当前分页信息
                        sessionStorage.filePage = JSON.stringify({ pageIndex: this.pageIndex, pageNum: this.pageNum })
                        this.$router.push('/analysis')
                      }
                    } else {
                      this.$message.error('匹配不到测温分析文件，不能进入测温分析')
                    }
                  } catch (error) {
                    if (this.isClick === 2) {
                      this.$message.error('文件解析失败，不能进入测温分析')
                    }
                  }
                }
              })
              .catch((err) => {
                console.log('err => ', err)
              })
          } else if (this.currentFile.name.indexOf('-DC') !== -1) {
            this.getUrlByName(this.currentFile.name.replace('-DC', '-PX1'), resolve)
          } else if (this.isClick === 2) {
            this.$message.error('匹配不到测温分析文件，不能进入测温分析')
          }
        }
      })
    },
    // 单击
    imgClick({ item, index, resolve, reject, isClick }) {
      this.currentIndex = index
      this.currentFile = item
      this.showFlag = true
      clearTimeout(this.timeOutId)
      this.timeOutId = setTimeout(() => {
        // 图像点击
        if (isClick) {
          this.isClick = isClick
        }

        // 处理非irg jpg后缀的文件
        let name = item.name
        if (!name || ['irg', 'jpg'].indexOf(name.split('.')[name.split('.').length - 1]) === -1) {
          this.showEnvironment = false
          this.imgInfo.imgObj = {
            name: this.currentFile.name,
            uploadTime: this.currentFile.uploadTime,
            fileSize: changeToThousand(this.currentFile.size.toString()) + 'KB'
          }
          return
        } else {
          this.showEnvironment = true
        }

        // 是否国网
        // 获取 url
        let irUrl = ''
        let dcUrl = ''
        let px1Url = ''
        let isIR = false
        let isDC = false
        let isPX1 = false
        if (name.indexOf('-IR') !== -1) {
          isIR = true
          name = name.replace('-IR', '-DC')
          irUrl = item.url
        } else if (name.indexOf('-DC') !== -1) {
          isDC = true
          name = name.replace('-DC', '-IR')
          dcUrl = item.url
        } else if (name.indexOf('-PX') !== -1) {
          isPX1 = true
          px1Url = item.url
        }

        if (isIR || isDC) {
          new Promise((resolve) => {
            this.getUrlByName(name, resolve, irUrl)
          }).then((otherUrl) => {
            if (otherUrl) {
              if (isIR) {
                dcUrl = otherUrl
              } else {
                irUrl = otherUrl
              }
              // 获取dcList irList
              const p1 = new Promise((resolve) => {
                this.getImgData(irUrl, 'ir', resolve)
              })
              const p2 = new Promise((resolve) => {
                this.getImgData(dcUrl, 'dc', resolve)
              })
              Promise.all([p1, p2]).then(() => {
                // 环境信息
                let params = {}
                try {
                  params = getParams({
                    isGW: true,
                    irU8Array: this.irList,
                    dcU8Array: this.dcList
                  })
                } catch (error) {
                  console.log('err =>', error)
                  if (this.isClick === 2) {
                    this.$message.error('同组测温文件来源不一致，不能进入测温分析')
                  }
                  if (reject) {
                    reject()
                  }
                }
                this.imgInfo.envObj = params

                // 设备信息
                const { suit, model, name } = this.currFolder
                this.imgInfo.deviceObj = { suit, model, name }

                // 图像信息
                this.imgInfo.imgObj = {
                  name: this.currentFile.name,
                  uploadTime: this.currentFile.uploadTime,
                  fileSize: changeToThousand(this.currentFile.size.toString()) + 'KB',
                  dpi: params.iwidth && params.iheight ? params.iwidth + '*' + params.iheight : ''
                }
                if (resolve) {
                  resolve()
                }
              })
            } else {
              this.$message.error('获取图像地址失败')
            }
          })
        } else if (isPX1) {
          // 获取PX1List
          axios
            .get((px1Url.indexOf('aliyuncs') !== -1 ? '/alImg' : '/dfsImg') + px1Url.substr(findStr(px1Url, '/', 2)), {
              responseType: 'blob'
            })
            .then((res) => {
              const blob = new Blob([res.data])
              const reader = new FileReader()
              reader.readAsArrayBuffer(blob)
              reader.onload = (e) => {
                const list = new Uint8Array(e.target.result)

                // 环境信息
                let params = {}
                try {
                  params = getParams({ isPX1: true, irU8Array: list })
                } catch (error) {
                  if (this.isClick === 2) {
                    this.$message.error('解析文件失败，不能进入测温分析')
                  }
                  if (reject) {
                    reject()
                  }
                }
                this.imgInfo.envObj = params

                // 设备信息
                const { suit, model, name } = this.currFolder
                this.imgInfo.deviceObj = { suit, model, name }

                // 图像信息
                this.imgInfo.imgObj = {
                  name: this.currentFile.name,
                  uploadTime: this.currentFile.uploadTime,
                  fileSize: changeToThousand(this.currentFile.size.toString()) + 'KB',
                  dpi: params.iwidth && params.iheight ? params.iwidth + '*' + params.iheight : ''
                }

                if (resolve) {
                  resolve()
                }
              }
            })
            .catch((err) => {
              console.log('err => ', err)
            })
        } else {
          // irg
          new Promise((resolve) => {
            this.getUrlByName(name.replace('jpg', 'irg'), resolve)
          }).then((url) => {
            if (url) {
              // 获取irgList
              this.getImgData(url, 'irg', resolve, reject)
            } else {
              this.$message.error('获取图像地址失败')
            }
          })
        }
      }, 200)
    },
    getDataList(flag) {
      for (let i = 0; i < this.checkedList.length; i++) {
        this.$set(this.checkedList, i, false)
      }
      if (flag) {
        this.pageIndex = 1
      } else {
        this.pageIndex++
        if (this.pageIndex > this.totalPage) {
          return
        }
      }
      // 图像
      this.$http({
        url: this.$http.adornUrl('/api/v1/files/list'),
        method: 'get',
        params: this.$http.adornParams({
          deviceId: this.currFolder.deviceId,
          pageNum: this.pageIndex,
          pageSize: this.pageSize,
          name: this.dataForm.name,
          beginUploadTime: this.dataForm.dateTime ? this.dataForm.dateTime[0] : null,
          endUploadTime: this.dataForm.dateTime ? this.dataForm.dateTime[1] : null
        })
      }).then((res) => {
        if (res.code === 0) {
          const data = res.data || {}
          const list = data.list || []
          list.forEach((item) => {
            if (item.url.indexOf('group1') !== -1) {
              item.url = changeFileUrl(item.url)
            }
            if (item.name && item.name.split('.')[item.name.split('.').length - 1] === 'irg') {
              this.$http({
                url: this.$http.adornUrl('/api/v1/files/downloadFile'),
                method: 'post',
                params: this.$http.adornParams({
                  deviceId: this.currFolder.deviceId,
                  name: item.name.replace('.irg', '.jpg'),
                  size: 0
                })
              }).then((res) => {
                if (res.code === 0) {
                  this.$set(item, 'viewUrl', item.url.indexOf('group1') !== -1 ? changeFileUrl(res.data) : res.data)
                }
              })
            }
          })

          if (flag) {
            this.dataList = list
            // 重置数据
            this.showFlag = false
            this.currentIndex = undefined
            this.imgInfo = {
              temUnit: 1,
              disUnit: 1,
              envObj: {
                fHumidity: undefined,
                fEmiss: undefined,
                fDistance: undefined,
                fAirTemp: undefined,
                fReflectTemp: undefined
              },
              deviceObj: {
                suit: '',
                model: '',
                name: ''
              },
              imgObj: {
                name: '',
                dpi: '',
                uploadTime: ''
              }
            }
          } else {
            this.dataList.push(...list)
          }
          this.totalPage = data.pages
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    refreshForm() {
      this.$refs.dataForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
}
.info-aside {
  overflow: auto;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2);
  border-radius: 8px;
  background: white;
  ::v-deep .el-input__inner {
    height: 40px;
  }
  .form-class {
    margin-left: 15px;
  }
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
.el-divider__text {
  font-weight: bold;
}
.el-divider--horizontal {
  background: #5053dd;
  margin: 24px auto;
  width: 90%;
}
::v-deep .el-form-item__content {
  margin-right: 20px;
  box-sizing: border-box;
}
.main {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.inner-img {
  width: 196px;
  height: 226px;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  .img-item {
    width: calc(100% - 46px);
    height: 180px;
    margin: 18px auto;
    display: flex;
    justify-content: center;
  }
  .img-title {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px;
    position: absolute;
    bottom: 8px;
    left: 0;
    text-align: center;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .img-check {
    position: absolute;
    top: -2px;
    left: 4px;
  }
  .more {
    position: absolute;
    top: -2px;
    right: 4px;
  }
  .more:hover {
    color: #a8a9ee;
  }
  .more:active {
    color: #5053dd;
  }
  .img-view {
    position: absolute;
    bottom: 24px;
    left: 0px;
    color: gray;
  }
  .img-view:hover {
    color: #7375e4;
  }
}
.popover-btn {
  cursor: pointer;
}
.popover-btn:hover {
  color: #a8a9ee;
}
.popover-btn:active {
  color: #5053dd;
}
::v-deep .el-input-number {
  width: 100%;
}
</style>
